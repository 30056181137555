<template>
  <div class="row">
    <div class="col-md-12">
      <v-alert border="right" colored-border type="error" class="text-white" elevation="2" v-if="errorMessaages">
        {{ errorMessaages }}
      </v-alert>

      <v-card>
        <v-card-title>

          <div style="display: flex;align-items:center;jutify-content:space-between;width:100%">
            <v-text-field v-model="rate" :counter="255" label="Daily Exchange Rate" required
              :error-messages="errors.title"></v-text-field>
            <v-btn color="success" class="ml-5" @click="submit"> Submit </v-btn>
          </div>


        </v-card-title>

        <v-data-table :headers="headers" item-key="exchangeRate" :items="exchanges" class="elevation-1 tb">

          <template v-slot:item.actions="{ item }">
            

              <v-icon @click="remove(item)">mdi-delete</v-icon>


          </template>

          <template v-slot:item.exchangeRate="{ item }">
            100$ = {{ item.exchangeRate }} IQD
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import AlertService from "@/core/services/alert.service";
export default {
  data() {
    return {
      page: 1,

      headers: [
        {
          text: "#",
          align: "left",
          value: "id",
          sortable: false,
        },
        {
          text: "Exchange Rate",
          align: "left",
          value: "exchangeRate",
          sortable: false,
        },
        {
          text: "Date",
          align: "left",
          value: "created_at",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ],
      rate: '',
      exchanges: [],
      errorMessaages: "",
      errors: [],
    };
  },
  methods: {
    submit() {
      ApiService.post("/exchange/create", {
        'exchangeRate': this.rate
      })
        .then((response) => {
          console.log(response);
          AlertService.successMessage();
          this.rate='';
          this.get();
        })
        .catch((error) => {
          if (!error.response) {
            this.errorMessaages = error;
          } else if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    get() {
      ApiService.get("/exchange/get?page=&per_page=10")
        .then((response) => {
          this.exchanges = response.data.data;
        })
        .catch((error) => {
          if (!error.response) {
            this.errorMessaages = error;
          } else if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    remove(item) {
      AlertService.confirm().then((response) => {
        if (response) {
          ApiService.delete("/exchange/delete/" + item.id)
            .then(() => {
              AlertService.successMessage();
              this.get();
            })
            .catch((error) => {
              if (!error.response) {
                this.errorMessaages = error;
              } else if (error.response.status == 422) {
                this.errors = error.response.data.errors;
              }
            });
        }
      });
    },
  },
  created() {
    this.get();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "List", route: "exchanges" },
      { title: "exchanges" },
    ]);
  },
};
</script>

<style>

@media screen  and (min-width: 768px){
    .v-card {
      width: 600px;
    }
}

.v-application {
  background: none !important;
}


.theme--light.v-sheet,

.theme--light.v-card {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.theme--light.v-data-table {
  background-color: transparent;
}

th,
td,
.v-data-footer,
.v-label {
  color: white !important;
}

button .v-icon {
  color: red !important;
}

tr:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: cornflowerblue;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: white !important;
}

input {
  color: white !important;
}

.theme--light.v-input,
.mdi-delete {
  color: white !important;
}

</style>
